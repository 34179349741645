import React from "react"
import { graphql } from "gatsby"
import Navigation from "../components/navigation"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Especifico from "../images/SVG/especifico.svg"
import Footer from "../components/footer"

const IndexPage = props => (
  <Layout>
    <SEO title="Contacto" />
    <Navigation />

    <div className="w-full mx-auto top-illustration">
      <Img fluid={props.data.oneImage.childImageSharp.fluid} className="h-64" />
    </div>

    <div className="w-full mx-auto text-center bg-blue-200 pt-8 pb-8">
      <h1 className="text-3xl lg:text-4xl text-blue-900">Contacto</h1>
      <div>
        <p className="w-10/12 lg:w-1/2 text-lg mt-3 mx-auto">
          Para hacer donaciones, contratar talleres o ponerse en contacto con
          nosotros use la siguiente información
        </p>
      </div>
    </div>

    <div className="w-full mx-auto bg-green-100 pt-8 pb-8">
      <div className="content-item w-full lg:w-10/12 lg:flex mx-auto">
        <div className="w-full lg:w-1/4 text-center">
          <img
            src={Especifico}
            alt="Mindfulness y Arteterapa"
            className="mx-auto w-1/2 lg:w-8/12"
          />
        </div>
        <div className="w-full lg:w-3/4">
          <p className="px-5 pt-10">
            Nos puede escribir al email{" "}
            <a href="mailto:info@asccr.org" className="underline">
              info@asccr.org
            </a>
            . Si nos quiere contactar por medio de mensaje SMS o Whatsapp nos
            puede escribir al{" "}
            <a href="tel:+50684479964" className="underline">
              +506 8447-9964
            </a>
            .
          </p>
        </div>
      </div>
    </div>

    <div className="w-full mx-auto text-center bg-gray-100 pt-8 pb-8">
      <h1 className="text-2xl lg:text-4xl text-blue-900">
        Los objetivos de desarrollo sostenible en que contribuimos
      </h1>
      <div className="mt-5">
        <p className="w-2/3 lg:w-8/12 lg:w-1/2 text-sm lg:text-lg mt-3 mx-auto">
          Garantizar una vida sana y promover el bienestar de todos a todas las
          edades.
        </p>

        <p className="w-2/3 lg:w-8/12 lg:w-1/2 text-sm lg:text-lg mt-8 mx-auto">
          Promover sociedades pacíficas e inclusivas, facilitar el acceso a la
          justicia y construir instituciones eficaces que rindan cuentas.
        </p>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default IndexPage

export const anaQuery = graphql`
  query {
    oneImage: file(relativePath: { eq: "contacto.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
